footer{
    background-color: black;
    padding: 2% 0;
    h4{
        text-align: center;
        color: white;
        font-size: 1.5vw;
    }
}

// ======================================== Media query ====================

@media only screen and (max-width:800px){
    footer{
        h4{
            font-size: 3vw;
        }
    }
}