.contact {
  background-color: rgb(0, 153, 255);
  width: min(100%,600px);
  margin: 1rem auto;
  border-radius: 10px;

  .info {
    display: flex;
    justify-content: center;
    .spanEmail {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 13rem;
      h2 {
        padding: 0;
        margin: 0;
        font-size: 1.4rem;
        white-space: nowrap;
      }
      a {
        // margin: 0;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        color: white;
        text-decoration: none;
        transition: 0.7s;
        &:hover{
        transition: 0.7s;
            color: darkred;
        }
        &:active{
          transform: scale(0.9);
      }
      }
    }
    .spanLogo {
      width: 3rem;
      margin-left: 1rem;

      a {
        display: flex;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
