.baner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid gray;
  width: min(100%,1800px);
  margin: 0 auto 0.5rem auto;
  padding: 1.5rem;
  @media only screen and (max-width:901px) {
    padding: 0;
  }

  .left {
    // width: min(100%,700px);
    flex: 1 1 450px;
    // ================================== Media Query ======================
    @media only screen and (max-width:901px) {
      text-align: center;
      .h1-bg{
        background-color: rgb(0, 153, 255);
        padding-top: 0.5rem;
        h1{
          color: white !important;
        }
      }
     
    }
    // ================================== Ends Media Query ======================
    h1 {
      &:first-child {
        color: rgb(0, 132, 255);
        font-size: 4rem;
        margin-bottom:2rem;
      }
      &:nth-child(2) {
        font-size: 3rem;
       padding: 0.5rem;

      }
    }
    h2 {
      font-size: 2rem;
      padding: 0.5rem;
    }
    p {
      font-size: 1.6rem;
      padding: 0.5rem;
    }
  }

  .right {
    // width: 50%;
    flex: 1 1 450px;
    position: relative;
        // ================================== Media Query ======================
        @media only screen and (max-width:901px) {
     display: flex;
     justify-content: center;
     align-items: center;        
  }
        // ================================== Ends Media Query ======================

    padding: 5% 0 5% 5%;
    .shapeOne {
      // background: linear-gradient(to top,rgb(83, 67, 230),rgb(255, 69, 199));
      background: linear-gradient(to top, rgb(255, 255, 255),rgb(0, 153, 255));

      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      width: 60%;
      height: 90%;
      position: absolute;
      z-index: -1;
      top: 5%;
      left: 25%;
      animation: shape 4s infinite ease-in-out alternate;
    }

    img {
      width: 55%;
      border-radius: 50%;
      object-fit: contain;
      animation: banerImage 2s infinite ease-in-out alternate;
      animation-delay: 1.5s;
    }
  }
}


@keyframes shape {
  from {
    top: 5%;
  }
  to {
    top: 10%;
  }
}

@keyframes banerImage {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(5%);
  }
}

