header{
    z-index: 3;
    .logoContainer{
        border-bottom: 1px gray solid;
       padding: 1rem 0;
       width: min(100%,500px);
       margin: 0 auto 0.5rem auto;
       display: flex;
       justify-content: center;
       align-items: center;
    .logo{
        display: flex;
        width: 8rem;
        filter: drop-shadow(2px 3px 5px black);
        img{
            width: 100%;
            height: 100%;
            clip-path: circle(50%);
            animation: rotatingLogo 7s linear infinite alternate ;
        }
    }


   }
   
}
@keyframes rotatingLogo {
    0%{
        transform: rotate(20deg);
    }100%{
        transform: rotate(-20deg);
    }
}


