.form-main{
    background-color: rgb(0, 153, 255);
    padding:1rem;
    border-radius: 10px;

    .form{
        width: 100%;
        h1{
            text-align: center;
            color: white;
            font-size: 2rem;
        }
        form{
            // border: 1px red solid;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            gap: 1rem;
            input{
                font-size: 1.2rem;
                padding: 0.5rem;
                border-radius:5px;
                outline: none;
                border: 1px black solid;
                font-family: 'Courier New', Courier, monospace;
                // border-bottom: 1px solid rgb(201, 201, 201);
            }
            textarea{
                resize: none;
                font-size: 1.2rem;
                padding: 0.5rem;
                border-radius: 5px;
                font-family: 'Courier New', Courier, monospace;

            }
            input[type='submit']{
                background-color: rgb(44, 44, 44);
                color: white;
                font-weight: bold;
                border-radius: 10px;
                transition: 0.7s;

                &:hover{
                    transition: 0.7s;
                    background-color: darkred;
                }
            }
    
        }
    }

    .recaptcha{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 0 1.3rem 0;
        margin-top: 1rem;
    }
}
