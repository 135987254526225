.popupUpdates{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.795);
    display: flex;
    justify-content: center;
    align-items: center;
    .popupUpdatesContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        gap: 1rem;
        width: min(100%,500px);
        border-radius: 10px;
        background-color: rgb(0, 153, 255);
        span{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            // padding: 1rem;
            button{
                width: 4rem;
                font-size: 2rem;
                background:transparent;
                color: black;
                outline: none;
                border: none;
                cursor: pointer;
                transition: 0.7s;
                &:hover{
                    transition: 0.7s;
                    background-color: black;
                    color: white;
                }
            }
        }
        h1{
            text-align: center;
            width: 100%;
            font-size: 1.5rem;
        }
        p{
            font-size: 1.2rem;
            width: 100%;
            text-align: center;
        }
    }
}