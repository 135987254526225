.main-know{
    width: min(100% - 3%,1000px);
    margin: 2rem auto;
    h1{
        text-align: center;
        margin-bottom: 1rem;
    }
    .container{
        // width: 100%;
     
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        a{
            display: flex;
            width: 4rem;
            background-color: white;
            border-radius: 20px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}