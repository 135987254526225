@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Indie Flower', cursive;
    // user-select: none;

}
body{
    &::-webkit-scrollbar{
        background-color: black;
        
    }
   
    &::-webkit-scrollbar-thumb{
        background: linear-gradient(to bottom,black,gray);
        // width: 1vw;
        border-radius: 20px;
    }
}