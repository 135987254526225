.projects{
  width: 100%;
  .apps{
    width: min(100%,1700px);
    padding: 1rem;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    .singleApp{
      // width: 25rem;
      max-width: 15rem;
      @media only screen and (max-width:531px) {
        max-width: 100%;
      }
      flex: 1 1 250px;
      border: 1px gray solid;
      padding: 0.5rem;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .imgAnchor{
        display: flex;
        width: 100%;
        overflow: hidden;
        img{
          width: 100%;
          height: 15rem;
          object-fit: cover;
          border-radius: 20px;
          transition: 0.5s;
          &:hover{
            transition: 0.5s;
            filter: grayscale(1);
            transform: scale(1.3) rotate(10deg);
          }
        }
      }
      h4{
        font-size: 1.4rem;
      }
      .buttonVisit{
        width: min(100%,100px);
        background-color: rgb(0, 153, 255);
        padding: 0.2rem;
        text-align: center;
        border-radius: 20px;
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
        transition: 0.5s;
        &:active{
          background-color: white;
          color: black;
        }
        &:hover{
          transition: 0.5s;
        background-color: rgb(3, 114, 189);
        &:active{
          background-color: white;
          color: black;
        }
        }
      }
    }
  }
}